import { useEffect, useState, useRef } from 'react';
import useApi from '../../../hooks/useApi';
import { ReviewResponse } from '../../../models/reviews';

const useReviews = (defaultParams?: { order: string }) => {
  const applyFiltersRef = useRef({});
  const [errorMessage, setErrorMessage] = useState<string>();
  // const { response, isLoading, launchCall } =
  //   useApi<ReviewResponse>('admin/reviews');
    const { response, isLoading, launchCall } =
    useApi<ReviewResponse>('admin/v2/reviews');
  const { launchCall: launchActionCall } = useApi('admin/delete-review');

  useEffect(() => {
    // Let's make automatically since by the moment
    // there is no other reason to not do it.
    getReviews(defaultParams);
  }, []);

  const getReviews = (params?: Record<string, any>) => {
    try {
      applyFiltersRef.current = { ...applyFiltersRef.current, ...params };
      launchCall({ method: 'GET' }, applyFiltersRef.current);
    } catch (err) {
      console.log(err);
    }
  };

  const sendUserMessage = (message: string, reviewId: string) => {
    try {
      launchActionCall(
        {
          method: 'POST',
          body: JSON.stringify({
            reviewIds: [reviewId],
            messageText: message,
          }),
        },
        undefined,
        `admin/send-message`,
      );
    } catch (err) {
      console.log(err);
    }
  };

  const applyFilters = (sorting: { order?: string; search?: string }) => {
    // TODO ask BE guys to rename this param.
    getReviews({ ...{ ...sorting, id: sorting.search }, skip: 0 });
  };

  const handleDeleteReview = (id: string) => {
    launchActionCall(
      { method: 'DELETE', body: JSON.stringify({ reviewIds: [id] }) },
      undefined,
      `admin/delete-review`,
    );
  };

  const handlePagination = (page: number, offset: number) => {
    const identifier = page - 1;
    getReviews({ skip: identifier * offset });
  };

  const nextPage = () => {
    const nextPage = response?.meta.nextPage;
    if (nextPage) {
      handlePagination(nextPage, response.meta.limit);
    }
  };

  const prevPage = () => {
    const prevPage = response?.meta.prevPage;
    if (prevPage) {
      handlePagination(prevPage, response.meta.limit);
    }
  };

  return {
    getReviews,
    nextPage,
    prevPage,
    applyFilters,
    sendUserMessage,
    deleteReview: handleDeleteReview,
    reviews: response?.data,
    totalPages: response?.meta.totalPages,
    currentPage: response?.meta.page,
    error: errorMessage,
    isLoading,
  };
};

export default useReviews;
