import { format } from 'date-fns';
import { TableColumn } from '../components/ui/Table';
import { Reviews, ReviewTable } from '../models/reviews';

const ReviewsColumn: TableColumn<ReviewTable>[] = [
  { id: 'serial', label: '#' },
  { id: 'reviewId', label: 'Review ID' },
  { id: 'listingId', label: 'Listing ID' },
  { id: 'review', label: 'Provider Review' },
  { id: 'sender', label: 'Sender' },
  { id: 'receiver', label: 'Receiver' },
  { id: 'createdAt', label: 'Date' },
  { id: 'actions', label: 'Actions' },
];

export const ReviewToReviewTable = (providers: Reviews[]) => {
  const data: ReviewTable[] = providers.map(
    ({ _id, booking, listingId, comments, sender, receiver, createdAt }, index) => ({
      id: _id,
      serial: (index + 1).toString(),
      // bookingId: booking ? booking._id : '',
      // listingId: listing._id,
      reviewId: _id,
      listingId: listingId,
      review: comments,
      sender: sender[0]?.name,
      receiver: receiver[0]?.name,
      createdAt: format(createdAt ? new Date(createdAt): new Date(), 'MM-dd-yyy hh:mm a' ),
      actions: 'ACTIONS',
    }),
  );
  return { headers: ReviewsColumn, data };
};
