import Button from "../ui/Button";

type Props = {
    body: string;
    onClose: () => void;
  };

const Modal = ({ body, onClose }: Props) => {
    const bodyData = body.split('.');
  return (
    <>
    <div
      className="w-full h-full absolute top-0 left-0 bg-secondary-main opacity-70 z-50"
      onClick={onClose}
    />
    <div className="flex flex-col items-center fixed w-fit h-fit p-6 bg-white rounded-xl z-[60] top-0 left-0 right-0 bottom-0 m-auto">
     <h1 className="text-xl text-secondary-main mb-9 font-extrabold">Otp generated by this user listed below:</h1>
      <div className="w-full border p-4 rounded-sm mb-9 shadow-lg">
      {
        bodyData?.map((data, index) => (
            <p key={index} className="font-bold capitalize">{data}</p>
        ))
      }
      </div>
      <div className="flex w-full">
        <Button
          text="Close"
          onClick={onClose}
        />
      </div>
    </div>
  </>
  )
}

export default Modal