import Button from '../ui/Button';

type Props = {
  title: string;
  onConfirm: () => void;
  onClose: () => void;
};

const Alert = ({ title, onConfirm, onClose }: Props) => {
  return (
    <>
      <div
        className="w-full h-full absolute top-0 left-0 bg-secondary-main opacity-70 z-50"
        onClick={onClose}
      />
      <div className="flex flex-col items-center fixed w-fit md:max-w-[500px] h-fit p-6 bg-white rounded-xl z-[60] top-0 left-0 right-0 bottom-0 m-auto">
        <h1 className="text-xl text-secondary-main mb-9">{title}</h1>
        <div className="flex w-full">
          <Button text="Confirm" onClick={onConfirm} />
          <Button
            className="ml-6"
            styleType="secondary"
            text="Cancel"
            onClick={onClose}
          />
        </div>
      </div>
    </>
  );
};

export default Alert;
