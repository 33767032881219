import useApi from '../../hooks/useApi';
import { ForgotPasswordResponse } from '../../models/forgot-password';

const useResetPassword = () => {
  const { launchCall, status, response, isLoading, error } =
    useApi<ForgotPasswordResponse>('users/change-password');

  const changePassword = (currentPassword: string, newPassword: string) => {
    launchCall({
      method: 'POST',
      body: JSON.stringify({
        currentPassword,
        newPassword,
      }),
    });
  };

  return { response, changePassword, isLoading, error, status };
};

export default useResetPassword;
